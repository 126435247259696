<template>
  <div class="page">
    <span class="title">让投放不再是体力工作，聚焦投放效果</span>
    <span class="tip"
      >版本之间支持升级，跟随团队需求动态扩容，每一笔花费都物超所值。</span
    >
    <div class="products">
      <div class="product">
        <div class="product-header" style="background:#3280FA"></div>
        <div class="product-content">
          <span class="product-title">千川词霸</span>
          <p class="product-tip">
            海量词库，优选算法，快速关联创意关键词，自动匹配目标覆盖人数
          </p>
          <div class="price-container">
            <span class="ft45 price">99</span>
            <span class="ft18">元/月/人</span>
          </div>
          <router-link to="/xc">
            <span class="button-ci">立即使用</span>
          </router-link>
          <div class="introduce-container">
            <div class="introduce-tip">
              功能
            </div>
            <span>自定义输入关键词</span>
            <span>自动根据输入关键词关联创意词条</span>
            <span>自定义增减创意词条</span>
            <span>一键添加/删除词条</span>
            <span>云端关联词库（生成词条时自动调用）</span>
            <span>一键自动添加行为词、兴趣词、创意词</span>
            <span> 自动匹配目标覆盖人数</span>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="product-header" style="background:#FF2626"></div>
        <div class="product-content">
          <span class="product-title">千川管家</span>
          <p class="product-tip">
            具备自定义数据预警阈值，自动关闭计划
            授权后可自动关联千川投放计划数据
          </p>
          <div class="price-container">
            <span class="ft45 price">99</span>
            <span class="ft18">元/月/人</span>
          </div>
          <router-link to="/yj">
            <span class="button-yu">立即使用</span>
          </router-link>
          <div class="introduce-container">
            <div class="introduce-tip">
              功能
            </div>
            <span>多维度独立设定预警阈值</span>
            <span>根据当前资金消耗速度预估结束时间</span>
            <span>预警/关闭信息显示</span>
            <span>自动关闭到达阈值的计划</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {},
  methods: {
    // ------------------ 点击方法
    // ------------------ 请求方法
    // ------------------ 其它
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
.title {
  color: #000;
  font-size: 40px;
  font-weight: bold;
  line-height: 70px;
  margin-top: 98px;
}
.tip {
  font-size: 18px;
  font-weight: 400;
  color: #86868b;
  line-height: 30px;
  margin-top: 20px;
}
.products {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}
.product {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 396px;
  height: 705px;
  border: 1px solid #ebebeb;
  border-radius: 14px;
  margin: 0 52px 104px;
  .product-header {
    height: 12px;
    width: 100%;
    border-radius: 14px 14px 0 0;
  }
  .product-content {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 0;
    align-items: center;
    .product-title {
      font-size: 20px;
      font-weight: bold;
      color: #282828;
      line-height: 30px;
    }
    .product-tip {
      font-size: 14px;
      font-weight: 400;
      color: #8e8e8e;
      line-height: 24px;
      margin-top: 26px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .price-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      color: #000000;
      justify-content: center;
      .price {
        position: relative;
        bottom: -12px;
      }
    }
    .button-ci {
      width: 326px;
      height: 54px;
      background: #e5efff;
      border: 1px solid #2b7af9;
      color: #2b7af9;
      border-radius: 25px;
      margin-top: 30px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .button-yu {
      width: 326px;
      height: 54px;
      background: #fff0f0;
      border: 1px solid #ff2626;
      color: #ff2626;
      border-radius: 25px;
      margin-top: 30px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .placeholder-line {
      width: 100%;
      border: dashed 1px #d4d4d4;
      margin-top: 48px;
    }
    .introduce-container {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      line-height: 30px;
      color: #555;
      .introduce-tip {
        color: #b7b7b7;
      }
    }
  }
}
</style>
